<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="75" class="progress--header"></ProgressBar>

      <h1 class="page__header">The Blessings</h1>

      <p class="page__description">
        From ancient times, parents have blessed their children and future
        generations. The parental blessing is a powerful propellant of future
        success, and we have some suggestions here that you can use
      </p>

      <div class="step">
        <p class="step__text">Check all that apply</p>

        <div class="step__input">
          <div
            class="step__fields"
            v-for="(options, index) in blessings"
            :key="index"
          >
            <label class="step__fields__label">{{ deSlug(index) }}</label>
            <div class="field" v-for="(option, i) in options" :key="i">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    :id="`${index}${i}`"
                    :name="`${index}`"
                    :value="option"
                    autocomplete="false"
                    v-model="blessingsSelected[index]"
                    required
                  />
                  <label :for="`${index}${i}`"><span></span></label>
                </div>
                <label :for="`${index}${i}`">{{ deSlug(option) }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link
            :to="{ name: 'Regrets' }"
            class="button button--secondary"
            >Go Back</router-link
          >
          <button @click="routeToNextPage" class="button button--primary">
            Continue
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/blessings.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import ProgressBar from '@/components/ui/progress-bar'
import { deSlug } from '@/utils/convert'

export default {
  name: 'Blessings',
  components: { ProgressBar },
  data() {
    return {
      blessingsSelected: {
        birth_order_wishes_and_blessings: '',
        those_in_education: '',
        those_in_employment: '',
        those_who_own_their_own_business: '',
        marriage: '',
        those_with_children: '',
      },
      blessings: {
        birth_order_wishes_and_blessings: [
          'first_born',
          'middle_children',
          'last_born',
        ],
        those_in_education: [
          'those_about_to_enter',
          'those_already_within_school',
          'those_leaving_or_who_have_just_left',
        ],
        those_in_employment: [
          'entry_level',
          'mid_management',
          'senior_positions',
        ],
        those_who_own_their_own_business: [
          'starting_out',
          'building_out',
          'handing_over',
        ],
        marriage: [
          'for_the_first_time',
          'for_the_second_time',
          'delayed_marriage',
        ],
        those_with_children: [
          'very_young_children',
          'young_adults',
          'grown_children_with_their_own_children',
          'married_without_children',
        ],
      },
    }
  },
  created() {
    let storedData = storage.getDataFromLocalStorage('blessings')
    if (storedData) {
      this.blessingsSelected = storedData
    }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('blessings', this.blessingsSelected)
      this.$router.push({ name: 'Closing' })
    },

    deSlug(text) {
      return deSlug(text)
    },
  },
  computed: {
    validateInput() {
      for (let i in this.blessingsSelected) {
        if (this.blessingsSelected[i] === '') {
          return false
        }
      }
      return true
    },
  },
}
</script>
